:root{
  --primary:#9e29d0;
  --secondary:#c36de7;
  --bg:#43234d;
  --textColor: rgb(255, 255, 255);
}
/* fazal csss  */
.dashboard .custom-header{
position: unset !important;
z-index: 0 !important;
margin-left: 0px !important;
background-color: var(--secondary)!important;
box-shadow: none !important;
}
.dashboard main.MuiBox-root.css-1w1gdci{
background-color: var(--secondary) !important;

}
.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  background-color: var(--secondary) !important;
}

.dashboard .css-1x9x74r-MuiDrawer-docked .MuiDrawer-paper{
background-color: var(--secondary) !important;
}
.dashboard .MuiDrawer-root.MuiDrawer-docked.css-1x9x74r-MuiDrawer-docked{
border-right: 1px solid #202E4E;
}
.dashboard .logo.d-flex{
background-color: var(--bg) !important;
justify-content: center;

} 
.dashboard .logo.d-flex a > img{
background-color: var(--bg) !important;
}
.dashboard .active-list-main .text-grey{
  color: var(--primary) !important;

}
.dashboard .active-list-main{
background-color: transparent !important;
border: none !important;
color: var(--primary) !important;
box-shadow: none !important;
}
.dashboard .list-main{
border: none !important;
color: #949494 !important;
}
.dashboard .MuiListItemIcon-root{
min-width: 40px !important;
}
.dashboard main header .main-nav{
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}
.dashboard main header .main-nav .login-box{
width: 100% !important;
flex-basis: 100%;
}

.dashboard .content .heading h3, .content .heading h6, .content .heading h5{
color: white;
}
.dashboard .dashboard-box{
background-color: var(--bg) !important;
border-radius: 20px !important;
border: none !important;
padding: 1rem 1rem !important;


}
.dashboard .dashboard-box.first-box{
background-color: var(--primary)!important;
border-radius: 20px !important;
border: none !important;
padding: 1rem 1rem !important;

}
.dashboard .box-layer-1{
background-color: var(--bg);
padding: 1rem 1rem;
}
.dashboard .box-layer-2{
background-color: #1F193C;
padding: 1rem 1rem;
border-radius: 20px;
}
.dashboard .box-layer-1 .color-primary{
color: white !important;
}
.sell-box-layer-1{
background-color: var(--textColor);
padding: 3px;
border-radius: 20px;
}
.sell-box-layer-2{
background-color: #1F193C;
padding: 1rem 1rem;
border-radius: 20px;
}
.sell-box{
background-color: var(--bg) !important;
padding: 1rem 1rem;
border-radius: 20px !important;
position: relative;
}
.sell-box .nft-img{
width: 100%;
}
.sell-box .nft-profile{
position: absolute;
right: 5px;
bottom: -20px;
width: 49px;
height: 49px;
}
.sell-box-body{
margin-top: 2rem;
}
.sell-box-body h3{
color: white;
margin-bottom: 4px;
font-size: 21px;
}
.sell-box-body h4{
color: white;
font-size: 18px;
}
.sell-flex-price{
display: flex;
justify-content: space-between;
align-items: center;
}

.sell-flex-price p{
color: var(--primary);
font-size: 17px;
}
.dashboard .nft-footer{
text-align: center;
margin-top: 0.5rem;
}
.dashboard .custom-mt-1{
margin-top: 1rem !important;
}
.dashboard .main-nav{
padding-left: 0px !important;
padding-right: 0px !important;
}
.dashboard .main-nav .nav-dropdown{
margin-left: 7px !important;
}
.dashboard .p-relative{
position: relative !important;
}
/* // certificate css  */
.portion-address {
margin-top: 1rem;
}
.portion-address p {
margin-bottom: 0;
font-size: 18px;
color: var(--primary);
}
.portion-address span{
font-size: 14px;
color: var(--primary);
}
.position-relative{
position: relative;
}
.nft-tag{
position: absolute;
z-index: 1;
background: var(--primary);
top: 0;
right: 0;
padding: 0.2rem 0.5rem;
color: white;
}
.qr-code-section img{
width: 200px;
}
.certificate-modal .css-rnmm7m-MuiPaper-root-MuiDialog-paper{
  height: 70%;
border-radius: 20px !important;
background-color: var(--secondary);
position: relative;
/* background-color: white; */

}
.certificate-modal .MuiDialogTitle-root{
background-color: var(--primary) !important;
text-align: center;
color: white;
}
.building-img{
width: 90%;
}
.certificate-modal .MuiDialogContent-root{
padding: 3rem 1rem !important;
padding-top: 2rem !important;
}
.certificate-modal .building-detail{
color: var(--primary);
list-style: none;
padding: 0;
display: flex;
flex-wrap: wrap;
}
.certificate-modal .building-detail li{
width: 29%;
margin-bottom: 1rem;
padding: 0 0.5rem;

}
.certificate-modal .building-detail li p{
margin-bottom: 0;
font-size: 18px;
font-weight: 550;
color: white;
}
.certificate-modal .building-detail li span{
font-size: 14px;
}
.open-sidebar{
display: none;
}
.close-sidebar{
display: none;
}
.MuiInput-input,.css-1x51dt5-MuiInputBase-input-MuiInput-input {
color: #ffffff !important;
border-color: white !important;
}
.css-89bi5l-MuiInputBase-root-MuiInput-root,.css-h7l260-MuiFormControl-root-MuiTextField-root{
border-color: white !important;
}
.left-sidebar{
position: fixed;
z-index: 999;
width: 250px;
height: 100vh;
top: 0;
right: 0;
background-color: #1F193B;
opacity: 1;
padding: 1rem 1rem;
overflow: auto;
transition: all ease 0.5s;
}
.left-sidebar header{
display: flex;
justify-content: center;
align-items: center;
margin-top: 3rem;
}
.custom-btn{
border-radius: 5px;
border: 1px solid;
padding: 0.3rem 1rem;
font-size: 15px;
}
.primary-btn{
background: var(--primary);
box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
border-color: var(--primary);
color: white;
  cursor: pointer;

}
a:hover {
  color: white;
}
.white-btn{
color: #5C5C5C;
background: #ECECEC;
border-color: #ECECEC;
}
.left-sidebar .body-section{
padding: 0px 0px;
margin-top: 1rem;
}
.left-sidebar .body-section label{
color: var(--primary);
}
.left-sidebar .body-section .form-control{
background-color: var(--secondary) !important;
border: none !important;
color: var(--primary) !important;
white-space: wrap;
}
.left-sidebar .body-section .form-control:focus{
border: none !important;
outline: none !important;
box-shadow: none !important;
}
.form-footer-btn-group{
display: flex;
align-items: center;
justify-content: space-between;
padding: 1rem 1rem;
}
.danger-btn{
background-color: #FFBFBF;
border-color: #FFBFBF;
color: #DF4545;
}
.danger-btn-2{
background-color: #DF4545;
border-color: #DF4545;
color: white;
}
.light-primary-btn{
background-color: #DFD4FF;
border-color: #DFD4FF;
color: #8364E2;
}
.table-section tr{
margin-bottom: 1rem !important;
}
.table-section td,.table-section th{
border: none !important;
font-size: 16px;
padding: 0.5rem 0.5rem;
}
.table-section td{
background-color: #1F193B;
color: var(--primary);
}
.table-section th{
color: #949494;
}
.user-action-dropdown-btn{
border: none !important;
padding: 0 !important;
color: var(--primary) !important;
background-color: unset !important;
}
.user-action-dropdown-btn:hover{
background-color: unset !important;
}
.user-action-dropdown-btn:focus{
box-shadow: none !important;
}
.user-action-dropdown-btn::after,.user-action-dropdown-btn::before{
display: none !important;
}
.user-action-dropdown .dropdown-menu{
width: 300px;
background-color: #1F193B !important;
padding: 1rem 1rem;
box-shadow: 0px 0px 20px rgba(131, 100, 226, 0.3);
}
.user-action-dropdown .profile-header{
text-align: center;
}
.user-action-dropdown .profile-header img{
width: 93px;
height: 93px;
border-radius: 50%;
}
.user-action-dropdown .profile-header p{
color: var(--primary);
margin-top: 1rem;
}
.user-action-dropdown .dropdown-body ul{
padding-left: 0;
list-style: none;
display: flex;
flex-direction: column;
}
.user-action-dropdown .dropdown-body ul li{
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 0.5rem;
}
.user-action-dropdown .dropdown-body ul li p{
margin: 0;
color: var(--primary);
font-size: 13px;
}
.user-action-dropdown .dropdown-body ul li span{
color: #949494;
font-size: 13px;
}
.user-action-footer{
display: flex;
align-items: center;
justify-content: space-evenly;
}
.dark-danger-btn{
background-color: #DF4545;
border: 1px solid #DF4545;
color: white;
}
.gray-btn{
background-color: #565656;
border: 1px solid #565656;
color: white;
}
input[type="checkbox"]{
accent-color: var(--primary);
}
.pointer{
cursor: pointer;
}
.left-sidebar .profile-image-section{
margin-top: 1rem;
}
.profile-image-section img{
width: 102px;
height: 102px;
border-radius: 50%;
}
.table-head-btn{
display: flex;
align-items: center;
}
.table-head-btn p{
color: #949494;
display: flex;
margin: 0;
}
.table-head-btn p span:not(:first-child){
margin-left: 10px;
}

.table-head-btn .btns{
margin-left: 0.5rem;
}
.table-head-btn .action-btn{
background: #1F193B;
border-radius: 5px;
color: var(--primary);
border: none;
padding: 0.5rem 1rem;
margin: 0 0.5rem;
}
.data-limit-dropdown .data-limit{
border-left: none;
border-right: none;
border-top: none;
border-bottom: 1px solid;
padding: 0;
margin-left: 10px;
color: #949494;
}
.data-limit-dropdown .dropdown-menu{
background-color: var(--bg);
min-width: 60px;
}
.data-limit-dropdown .dropdown-menu .dropdown-item{
color: white;
}
.data-limit-dropdown .dropdown-menu .dropdown-item:hover,.data-limit-dropdown .dropdown-menu .dropdown-item:focus,.data-limit-dropdown .dropdown-menu .dropdown-item:active{
background-color: var(--primary) !important;
}
.data-limit-dropdown .data-limit::before,.data-limit-dropdown .data-limit::after{
display: none !important;
}
.data-limit-dropdown .data-limit:hover,.data-limit-dropdown .data-limit:focus,.data-limit-dropdown .data-limit:active{
background-color: transparent !important;
box-shadow: none;
}
.table-footer-navigation{
display: flex;
align-items: center;
justify-content: flex-end;
padding: 0 2rem;
}
.table-footer-navigation p{
margin-bottom: 0;
color: #949494;
margin-left: 0.5rem;
}
.table-footer-navigation .btn-move{
background: var(--primary);
border: none;
padding: 0.2rem 0.5rem;
font-size: 20px;
color: white;
margin-left:0.8rem;
}
.table-footer-navigation .btn-move:disabled{
background-color: #C5B2FF !important;
}
.paginate-btn{
background: #1F193B;
border: none;
padding: 0.4rem 1rem;
color: #949494;
}
.send-box {
border-radius: 5px;
width: 28px;
height: 28px;
margin: auto;
}
.send-box img{
width: 95%;
}
.red-bg{
background: red;
}
.primary-bg{
background: #8364E2;
}
.certificate .custom-nav-tabs{
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin: auto;
}
.certificate .custom-nav-tabs li .img-div{
  border: 1px solid var(--primary);
  padding: 1rem;
  background-color: transparent;
  border-radius: 2rem;
  text-align: center;
}
.certificate .custom-nav-tabs li img{
  width: 30px;
  height: 30px;
}
.certificate .custom-nav-tabs li{
display: flex;
align-items: center;
flex-direction: column;
cursor: pointer;
}
.certificate .custom-nav-tabs li span{
  /* color: var(--primary); */
  color: white;
  margin-top: 0.5rem;
  font-size: 17px;
  font-weight: 500;
  background-color: var(--secondary) !important;
}
.certificate-modal .certificate-body .primary-btn{
  font-size: 20px !important;
  padding: 0.5rem 1.5rem !important;
}

.certificate .custom-nav-tabs li span :hover
{
  transition: background-color 1s;
  background-color: var(--primary);
}
.certificate .custom-nav-tabs li  :hover
{  
  
  transition: background-color 1s;
  background-color: white;
  /* color: black; */

}
.certificate-body{
  margin-top: 2rem;
}
.certificate-body .nft-circle{
  background-color: white;
  padding: 1rem;
  /* margin: auto; */
  text-align: center;
  height: 200px;
  width: 200px;
  border: 5px solid var(--primary);
  border-radius: 50%;
  position: relative;
}
.certificate-body .nft-circle .nft-img{
  width: 100px;
  height: 90px;
  margin-bottom: 1rem;
  cursor: pointer;
}
.certificate-body .nft-circle .tag{
  position: absolute;
  background-color: var(--primary);
  width: 200px;
  right: 0%;
  bottom: 0%;
  transform: translate(10px, 50%);
  border-radius: 10px;
  clip-path: polygon(100% 1%, 100% 50%, 100% 100%, 0% 100%, 5% 49%, 0% 0%);
}
.certificate-body .nft-circle .tag{
  padding: 0 1rem;
}
.certificate-body .nft-circle .tag span{
  font-size: 20px;
  font-weight: bold;
  color: white;

}
.certificate-body .nft-circle .rating img:nth-child(2){
  height: 40px;
  margin-bottom: 4px;
}
.nft-name{
  margin-top: 1rem;
}
.nft-name h5{
/* color: var(--primary); */
color: white;
text-transform: uppercase;

}
.nft-name h4{
/* color: var(--primary); */
color: white;
text-transform: uppercase;
}
.primary-input{
  background-color: #1F193C !important;
  border: none !important;
  color: white;
  margin-bottom: 0px !important;
  font-size: 19px;
  text-transform: uppercase;
}
.digital-building-detail {
  justify-content: space-between !important;
}
.digital-building-detail li{
  max-width: 33.33% !important;
  margin-top: 1rem;
}
.resell-main{
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1F193C;
  width: 100%;
  height: 0px;
  overflow: hidden;
}
body .resell-show{
  height: 100% !important;
  overflow: auto !important;
  padding: 2rem 3rem;
}
.primary-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.primary-scroll::-webkit-scrollbar-track {
  background: #131129; 
}
 
/* Handle */
.primary-scroll::-webkit-scrollbar-thumb {
  background: #f1f1f1; 
}

/* Handle on hover */
.primary-scroll::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1; 
}
body .custom-primary-group{
  display: flex;
}
body .custom-primary-group .form-control{
background-color: #131129;
border: 1px solid #131129;
border-radius: none !important;
color: white !important;
}
.custom-primary-group .form-control:focus{
  outline: none;
  box-shadow: none;
  border: 1px solid var(--primary);
}
.row-col-text{
  display: -webkit-flex;
  align-items: center;
}
.row-col-text .custom-primary-group .form-label{
  background-color: var(--primary);
  color: white !important;
  width: 100%;
  /* padding: 1rem 0; */
  padding: 0.5rem 0;
  text-align: center;
  border: 1px solid white;
  margin: 0 !important;
  /* font-size: 18px; */
  font-size: 14px;
}
.row-col-text .custom-primary-group .form-control{
  /* background-color: #ded4f9; */
  /* color: var(--primary) !important; */
  /* font-size: 18px; */
  font-size: 14px;
  margin: 0 !important;
  border-radius: 0px !important;
  /* padding: 1rem; */
  padding: 0.3rem 0 !important;
  border: 1px solid white;
  text-align: center;
}
.row-col-text .form-group{
  padding: 0px !important;
}
.custom-primary-group .form-label{
  /* color: var(--primary); */
  color: white;
  font-size: 18px;
}
.main-asset {
  padding: 1rem 0;
}
.main-asset .nft-img{
  width: 200px;
  height: 200px;
}
.asset-profile{
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1F193C;
  width: 100%;
  height: 0px;
  overflow: hidden;
}
.assets{
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--secondary);
  width: 100%;
  height: 0px;
  overflow: hidden;
}

.street-view-modal{
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1F193C;
  width: 100%;
  height: 0px;
  overflow: hidden;
  padding: 0px !important;
}
.main-asset:not(:last-child){
  /* border-bottom: 2px solid #31275E; */
}
.main-asset{
  /* width: 100%;
  overflow: auto; */
}
.custom-form-main{
  display: -webkit-box !important;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}
.custom-form-main .form-group{
  /* max-width: 33.3%; */
  padding: 0rem 0.5rem;
}
.group-flex-btn{
  display: flex;
}
.group-flex-btn .custom-btn{
margin-left: 0.5rem;
}
.street-header{
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}
.street-header svg{
  color: var(--primary);
  cursor: pointer;
}
.heading-btn{
  display: flex;
  align-items: center;
}
.heading-btn h3{
  margin-bottom: 0;
  color: white;
  margin-right: 3rem;
}
.property-inner-heading{
  margin-top: 1rem;
}
.property-inner-heading h4{
  color: white;
  font-weight: 500;
}
.property-inner-heading .coin-detail{
  display: flex;
  align-items: center;
}
.property-inner-heading .coin-detail img{
  width: 30px;
}
.property-inner-heading .coin-detail span{
  color: white;
  margin-left: 0.5rem;
}
.resell-main .sell-box-layer-1{
  margin-top: 1rem;
}
.dialog-close-header{
  display: flex;
  justify-content: flex-end;
}
.dialog-close-header svg{
  /* color: var(--primary); */
  color: white;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
.nft-profile.circle-div{
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.nft-profile.circle-div span{
  color: white;
}
.preview-image-modal .MuiDialogContent-root{
  background-color: var(--bg);
}
.preview-image-modal .dialog-close-header{
  margin-bottom: 0px !important;
}
.preview-image-inner{
  width: 400px;
  height: 400px;
}
.preview-image-main{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-dialog-header{
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 5px;
}
.preview-dialog-header svg{
  /* color: var(--primary); */
  color: white;
  cursor: pointer;
  margin: 0 0.5rem;
}
.dashboard .balance-box-main .MuiGrid-item{
  background-color: var(--primary);
  color: white !important;
  padding: 1rem 0;
  text-align: center;
  border: 1px solid white;
  margin: 0 !important;
  font-size: 18px;
  max-height: 70px !important;
}
.dashboard .balance-box-main .MuiGrid-item h6{
  margin-bottom: 0;
}
.dashboard .balance-box-main .MuiGrid-item:nth-child(2){
background-color: #131129 !important;
}
.custom-chain-dropdown{
  display: inline-block;
  margin-left: 1rem;
}
.custom-chain-btn-flex{
  display: flex;
  align-items: flex-start;
}
.custom-chain-dropdown .dropdown-toggle::after {
  display: none;
}
.custom-chain-dropdown .dropdown-menu{
/* background-color: ; */
}
.custom-chain-dropdown .dropdown-toggle{
    color: #fff !important;
    background: #8364e2;
    margin-left: 13px;
    border-radius: 6px;
    font-weight: 800;
    text-decoration: none;
    padding: 9px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
}
.custom-chain-dropdown .dropdown-toggle::before{
  display: none !important;
}
.custom-chain-dropdown .dropdown-toggle:hover{
  box-shadow: 2px 2px 20px 0px rgb(131 100 226 / 50%);
    transition: all 0.3s ease;
}
.deposit-modal h2{
  border-bottom: 1px solid var(--primary) !important;
  padding: 0.9rem 1rem !important;
  margin-bottom: 1rem;
  font-size: 18px;
}
.deposit-modal .MuiDialogContent-root{
  padding: 1rem 1rem;
}
.deposit-ul-detail{
  padding: 0;
  list-style: none;
  background-color: #f1efef;
  padding: 0.1rem 0.3rem;
}
.deposit-ul-detail li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}
.deposit-ul-detail li span{
  color: gray;
  font-size: 13px;
}
.deposit-ul-detail li p{
  margin-bottom: 0;
  font-size: 14px;
}
.flex-start{
  justify-content: flex-start !important;
}
/* end fazal css  */